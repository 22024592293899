import React from 'react';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import { useCarouselOptions } from './useCarouselOptions';

import type {
  CarouselApi, CarouselOptions, CarouselRef, CarouselSlidesOptions
} from './index';

interface Props extends CarouselOptions {
  children?: React.ReactNode | React.ReactElement[]
}

const DEFAULT_AUTOPLAY_DELAY = 4000;

export const CarouselProvider = (props: Props) => {
  const {
    slidesPerGroup,
    slidesPerView,
    spaceBetween,
    breakpoints,
    //
    autoplay,
    loop,
    drag = true,
    dragFree = false,
    duration = 25,
    //
    onInit,
    //
    children,
  } = props;

  const autoplayDelay = React.useMemo(() => (autoplay ? typeof autoplay === 'number' ? autoplay : DEFAULT_AUTOPLAY_DELAY : 0), [autoplay]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop, slidesToScroll: slidesPerView, watchDrag: drag, dragFree, duration
    },
    autoplay ? [Autoplay({ delay: autoplayDelay, stopOnMouseEnter: true, stopOnInteraction: false })] : []
  );

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);

  const options = useCarouselOptions({
    slidesPerView, slidesPerGroup, spaceBetween, breakpoints
  }, emblaApi);

  const scrollPrev = React.useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = React.useCallback(() => emblaApi?.scrollNext(), [emblaApi]);
  const scrollTo = React.useCallback((index: number) => emblaApi?.scrollTo(index), [emblaApi]);

  React.useEffect(() => {
    if (!emblaApi) return;

    const update = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };

    if (onInit) {
      onInit(emblaApi);
    }

    emblaApi.on('select', update).on('reInit', update);
    update();
    return () => {
      emblaApi.off('select', update).off('reInit', update);
    };
  }, [emblaApi, onInit]);

  const value = React.useMemo<CarouselContextType>(() => ({
    api: emblaApi,
    scrollPrev,
    scrollNext,
    canScrollNext,
    canScrollPrev,
    scrollTo,
    ref: emblaRef,
    slidesPerGroup: options.slidesPerGroup,
    slidesPerView: options.slidesPerView,
    spaceBetween: options.spaceBetween,
    selectedIndex,
  }), [
    emblaApi,
    scrollPrev,
    scrollNext,
    canScrollPrev,
    canScrollNext,
    scrollTo,
    emblaRef,
    options.slidesPerGroup,
    options.slidesPerView,
    options.spaceBetween,
    selectedIndex,
  ]);

  return (
    <CarouselContext.Provider value={value}>
      {children}
    </CarouselContext.Provider>
  );
};

interface CarouselContextType extends CarouselSlidesOptions {
  api: CarouselApi | undefined,
  ref: CarouselRef,
  scrollPrev: () => void,
  scrollNext: () => void,
  scrollTo: (index: number) => void,
  selectedIndex: number,
  canScrollPrev: boolean,
  canScrollNext: boolean
}

const CarouselContext = React.createContext<CarouselContextType | undefined>(undefined);

export const useCarousel = () => {
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarousel must be used within an CarouselProvider');
  }
  return context;
};
