import React from 'react';

import classnames from 'classnames';

import styles from './CarouselPagination.module.scss';

import { type CarouselPaginationProps, useCarousel } from './index';

const CarouselPagination = (props: CarouselPaginationProps) => {
  const { pagination, paginationClassName, renderPagination } = props;

  if (renderPagination) return renderPagination();

  if (!pagination) return null;

  // Hooks

  const { api, selectedIndex } = useCarousel();

  const totalSlides = api?.slideNodes().length || 0;

  // Handlers

  const handleDotClick = (index: number) => {
    api?.scrollTo(index);
  };

  return (
    <div className={classnames(
      styles.pagination,
      paginationClassName
    )}
    >
      {Array.from({ length: totalSlides }).map((_, index) => (
        <button
          key={index}
          type="button"
          className={classnames(styles.dot, {
            [styles.active]: selectedIndex === index
          })}
          onClick={() => handleDotClick(index)}
          //
          aria-label={`Go to slide ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default CarouselPagination;
