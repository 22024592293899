import React from 'react';
import classnames from 'classnames';

import CarouselNavigation from './CarouselNavigation';
import CarouselPagination from './CarouselPagination';
import CarouselProgressBar from './CarouselProgressBar';
import { CarouselProvider, useCarousel } from './CarouselProvider';
import CarouselSlides from './CarouselSlides';

import type { CarouselProps } from './index';

import styles from './Carousel.module.scss';

const Carousel = (props: CarouselProps) => {

  return (
    <CarouselProvider {...props}>
      <CarouselInner {...props} />
    </CarouselProvider>
  );
};

const CarouselInner = (props: CarouselProps) => {
  const {
    className,
    // Slides
    slideClassName,
    slidesClassName,
    slidesWrapperClassName,
    // Navigation
    prevClassName,
    prevIconClassName,
    nextClassName,
    nextIconClassName,
    onNextClick,
    onPrevClick,
    navigation,
    renderNavigation,
    onMouseEnter,
    onMouseLeave,
    // Pagination
    pagination,
    renderPagination,
    paginationClassName,
    // Progress Bar
    progressBar,
    renderProgressBar,
    progressBarClassName,
    //
    renderAfterSlides,
    renderBeforeSlides,
    //
    slidesPerView: initialSlidesPerView,
    slidesPerGroup: initialSlidesPerGroup,
    spaceBetween: initialSpaceBetween,
    breakpoints: initialBreakpoints,
    //
    children,
    ...rest
  } = props;

  const { slidesPerView, spaceBetween } = useCarousel();

  const navigationProps = React.useMemo(() => ({
    navigation,
    renderNavigation,
    prevClassName,
    prevIconClassName,
    nextClassName,
    nextIconClassName,
    onNextClick,
    onPrevClick,
    onMouseEnter,
    onMouseLeave,
  }), [
    navigation,
    renderNavigation,
    prevClassName,
    prevIconClassName,
    nextClassName,
    nextIconClassName,
    onNextClick,
    onPrevClick,
    onMouseEnter,
    onMouseLeave,
  ]);

  const paginationProps = React.useMemo(() => ({
    pagination,
    renderPagination,
    paginationClassName,
  }), [
    pagination,
    renderPagination,
    paginationClassName,
  ]);

  const progressBarProps = React.useMemo(() => ({
    progressBar,
    renderProgressBar,
    progressBarClassName
  }), [
    progressBar,
    renderProgressBar,
    progressBarClassName
  ]);

  const slidesProps = React.useMemo(
    () => ({
      slideClassName,
      slidesClassName,
      slidesWrapperClassName,
      renderBefore: renderBeforeSlides,
      renderAfter: renderAfterSlides,
    }),
    [slideClassName, slidesClassName, slidesWrapperClassName, renderAfterSlides, renderBeforeSlides]
  );

  const style = React.useMemo(
    () => ({
      '--carousel-slides-per-view': slidesPerView,
      '--carousel-slides-gap': typeof spaceBetween === 'number' ? `${spaceBetween}px` : spaceBetween,
    } as React.CSSProperties),
    [slidesPerView, spaceBetween]
  );

  return (
    <div
      className={classnames(styles.root, className)}
      style={style}
      {...rest}
    >

      {/* Navigation */}
      <CarouselNavigation {...navigationProps} />

      {/* Slides */}
      <CarouselSlides {...slidesProps}>
        {children}
      </CarouselSlides>

      {/* Pagination */}
      <CarouselPagination {...paginationProps} />

      {/* Progress Bar */}
      <CarouselProgressBar {...progressBarProps} />
    </div>
  );
};

export default Carousel;
