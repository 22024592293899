import React from 'react';
import classNames from 'classnames';

import { calculateSlideFlex } from 'helpers/CarouselHelpers';

import { useCarousel } from './CarouselProvider';

import styles from './Carousel.module.scss';

interface Props {
  children: React.ReactNode,
  slideClassName?: string,
  slidesClassName?: string,
  slidesWrapperClassName?: string,
  renderBefore?: () => JSX.Element,
  renderAfter?: () => JSX.Element
}

const CarouselSlides = (props: Props) => {
  const {
    slideClassName,
    slidesClassName,
    slidesWrapperClassName,
    renderBefore, renderAfter,
    children
  } = props;
  const { ref } = useCarousel();
  const id = React.useId();

  return (
    <div className={classNames(styles.slidesWrapper, slidesWrapperClassName)} ref={ref}>
      <div className={classNames(styles.slides, slidesClassName)}>
        {renderBefore?.()}
        {
          React.Children.toArray(children).map((child, index) => (child
            ? <CarouselSlide key={`${id}-${index}`} className={classNames(slideClassName, (child as any)?.wrapperClassName)}>{child}</CarouselSlide>
            : null
          ))
        }
        {renderAfter?.()}
      </div>
    </div>
  );
};

interface CarouselSlideProps {
  children: React.ReactNode,
  className?: string
}

const CarouselSlide = (props: CarouselSlideProps) => {
  const { children, className } = props;
  const { slidesPerView } = useCarousel();

  // TODO: think of a better way of applying this to a slide if slides per view is set
  const style = slidesPerView ? ({
    flex: calculateSlideFlex()
  }) : {};

  return (
    <div
      style={style}
      className={classNames(styles.slide, className)}
    >
      {children}
    </div>
  );
};

export default CarouselSlides;
