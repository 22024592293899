import React from 'react';
import classnames from 'classnames';

import type { CarouselApi, CarouselProgressBarProps } from './index';

import styles from './CarouselProgressBar.module.scss';

import { useCarousel } from './index';

const CarouselProgressBar = (props: CarouselProgressBarProps) => {
  const { progressBar, progressBarClassName, renderProgressBar } = props;

  if (renderProgressBar) return renderProgressBar();
  if (!progressBar) return null;

  // Hooks

  const { api } = useCarousel();

  // State

  const [scrollProgress, setScrollProgress] = React.useState(0);

  // Handlers

  const onScroll = React.useCallback((emblaApi: CarouselApi) => {
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
    setScrollProgress(progress * 100);
  }, []);

  React.useEffect(() => {
    if (!api) return;

    onScroll(api);
    api
      .on('reInit', onScroll)
      .on('scroll', onScroll)
      .on('slideFocus', onScroll);
  }, [api, onScroll]);

  return (
    <div className={classnames(styles.emblaProgress, progressBarClassName)}>
      <div
        className={styles.emblaProgressBar}
        style={{ transform: `translate3d(${scrollProgress}%,0px,0px)` }}
      />
    </div>
  );
};

export default CarouselProgressBar;
