import type React from 'react';

import Carousel from './Carousel';

import type { EmblaCarouselType } from 'embla-carousel';
import type { EmblaViewportRefType } from 'embla-carousel-react';

export { useCarousel } from './CarouselProvider';
export interface CarouselOptions extends CarouselSlidesOptions {
  autoplay?: boolean | number,
  loop?: boolean,
  drag?: boolean,
  dragFree?: boolean,
  duration?: number,
  breakpoints?: CarouselBreakpoints,
  onInit?: (api: CarouselApi) => void
}

export interface CarouselProps extends CarouselOptions, CarouselNavigationProps, CarouselPaginationProps, CarouselProgressBarProps {
  // classes
  className?: string,
  slidesWrapperClassName?: string,
  slidesClassName?: string,
  slideClassName?: string,
  slidesOffsetBefore?: string | number,
  slidesOffsetAfter?: string | number,
  //
  renderBeforeSlides?: () => JSX.Element,
  renderAfterSlides?: () => JSX.Element,
  //
  children?: React.ReactNode | React.ReactElement[]
}

export interface CarouselPaginationProps {
  pagination?: boolean | CarouselPaginationType,
  paginationClassName?: string,
  renderPagination?: () => React.ReactNode
}

export interface CarouselProgressBarProps {
  progressBar?: boolean | CarouselPaginationType,
  progressBarClassName?: string,
  renderProgressBar?: () => React.ReactNode
}

export type CarouselPaginationType = 'bullets' | 'dots';

export interface CarouselNavigationProps {
  prevClassName?: string,
  prevIconClassName?: string,
  nextClassName?: string,
  nextIconClassName?: string,
  //
  navigation?: boolean,
  renderNavigation?: () => React.ReactNode,
  //
  onNextClick?: (selectedIndex: number) => void,
  onPrevClick?: (selectedIndex: number) => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void
}

export interface CarouselSlidesOptions {
  slidesPerView?: number,
  slidesPerGroup?: number,
  spaceBetween?: number
}

export interface CarouselBreakpointOptions extends CarouselSlidesOptions {}

export type CarouselBreakpoints = Record<number, CarouselBreakpointOptions>;

export type CarouselApi = EmblaCarouselType;
export type CarouselRef = EmblaViewportRefType;

export default Carousel;
