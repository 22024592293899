import React from 'react';
import classNames from 'classnames';

import Icon from '../Icon/Icon';

import { useCarousel } from './CarouselProvider';

import type { CarouselNavigationProps } from './index';

import styles from './CarouselNavigation.module.scss';

const CarouselNavigation = (props: CarouselNavigationProps) => {
  const {
    scrollNext, scrollPrev, selectedIndex, canScrollPrev, canScrollNext
  } = useCarousel();
  const {
    navigation,
    renderNavigation,
    prevClassName,
    prevIconClassName,
    onPrevClick,
    nextClassName,
    nextIconClassName,
    onNextClick,
    //
    onMouseEnter,
    onMouseLeave
  } = props;

  const handlePrevClick = React.useCallback(() => {
    if (onPrevClick) {
      onPrevClick(selectedIndex);
    }
    scrollPrev();
  }, [onPrevClick, scrollPrev, selectedIndex]);

  const handleNextClick = React.useCallback(() => {
    if (onNextClick) {
      onNextClick(selectedIndex);
    }
    scrollNext();
  }, [onNextClick, scrollNext, selectedIndex]);

  // Render props
  if (renderNavigation) return renderNavigation();

  // Empty
  if (!navigation) return null;

  return (
    <>
      {
        canScrollPrev && (
          <NavigationButton
            className={classNames(styles.prev, prevClassName)}
            iconClassName={prevIconClassName}
            icon="chevron-left"
            ariaLabel="Previous"
            onClick={handlePrevClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )
      }
      {
        canScrollNext && (
          <NavigationButton
            className={classNames(styles.next, nextClassName)}
            iconClassName={nextIconClassName}
            icon="chevron-right"
            ariaLabel="Next"
            onClick={handleNextClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )
      }
    </>
  );
};

interface NavigationButtonProps {
  className?: string,
  iconClassName?: string,
  //
  icon?: string,
  ariaLabel?: string,
  //
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void
}

const NavigationButton = (props: NavigationButtonProps) => {
  const {
    className,
    iconClassName,
    //
    icon,
    ariaLabel,
    //
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={classNames(
        styles.navButton,
        className
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {icon && (
        <Icon
          className={classNames(styles.icon, iconClassName)}
          name={icon}
          size={24}
          strokeWidth={3}
        />
      )}
    </button>
  );
};

export default CarouselNavigation;
